import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import BannerImg from 'src/images/general-cashback-202303/banner.png';
import BannerSpImg from 'src/images/general-cashback-202303/banner-sp.png';
import { Hidden } from '@material-ui/core';
import styles from './CashbackTopBanner.module.scss';

export default function CashbackTopBanner({
  banner = BannerImg,
  bannerSp = BannerSpImg,
}) {
  return (
    <section className={styles.section}>
      <Hidden xsDown>
        <img alt="hero-banner" src={banner} className={styles.img} />
      </Hidden>
      <Hidden smUp>
        <img alt="hero-banner" src={bannerSp} className={styles.img} />
      </Hidden>
      <OutboundLink
        href="https://paidy.com/shop/"
        rel="noopener noreferrer"
        target="_blank"
        className={styles.btn}
      >
        使えるお店を探す
      </OutboundLink>
    </section>
  );
}

import React from 'react';
import TitleSection from 'src/pages/campaign_202303/TitleSection';
import ImgCashbackBanner2 from 'src/images/merchant/campaign/general_cashback_202305/cashback_banner_2.png';
import ImgCashbackBanner3 from 'src/images/merchant/campaign/general_cashback_202305/cashback_banner_3.png';
import { Box, Container } from '@material-ui/core';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { PAIDY_HOST } from 'src/constants';
import styles from './CashbackBanner202305.module.scss';

const BANNERS = [
  {
    src: ImgCashbackBanner2,
    name: 'Cashback banner 2',
    to: `${PAIDY_HOST}/campaign/incentiveCP_202305/`,
  },
  {
    src: ImgCashbackBanner3,
    name: 'Cashback banner 3',
    to: `${PAIDY_HOST}/campaign_202303/`,
  },
];

export default function CashbackBanner202305({ id, banners = BANNERS }) {
  return (
    <section className={styles.section} id={id}>
      <TitleSection
        text="CAMPAIGN"
        description="同時開催中のキャンペーンもお見逃しなく!"
      />
      <Container className={styles.container}>
        {banners.map((banner) => (
          <Box>
            <OutboundLink
              href={banner.to}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={banner.src}
                alt={banner.name}
                width={311}
                height={336}
              />
            </OutboundLink>
          </Box>
        ))}
      </Container>
    </section>
  );
}
